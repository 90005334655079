<template>
    <v-dialog
        v-model="show"
        max-width="650px"
        v-on:click:outside="cancelMethod"
        :persistent="consolidating"
    >
        <v-card>
                <v-toolbar
                    color="light-blue lighten-4"
                    class="text-h5"
                >Consolidamento Periodo
                    <v-spacer></v-spacer>
                    <v-btn 
                        icon
                        @click="cancelMethod"
                        v-if="!consolidating"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                     <v-stepper v-model="progress" elevation="0">
                        <v-stepper-header>
                            <v-stepper-step
                                :complete="progress > 1"
                                step="1"
                            >
                                Selezione Mese
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="progress > 2"
                                step="2"
                            >
                                Selezione Aziende
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step step="3">
                                Conferma
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-container fluid>
                                    <v-row justify="center">
                                        <v-date-picker
                                            v-model="picker"
                                            type="month"
                                            landscape
                                            locale="it"
                                            :show-current="false"
                                            color="light-blue lighten-2"
                                        ></v-date-picker>
                                    </v-row>
                                </v-container>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-container fluid>
                                    <v-select
                                        v-model="selectedCompanies"
                                        :items="selectableCompanies"
                                        label="Aziende"
                                        multiple
                                        chips
                                        hint="Seleziona le aziende da includere nel Consolidamento"
                                        persistent-hint
                                    ></v-select>
                                </v-container>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <v-container fluid>
                                    Alla fine del controllo verrà inviata una conferma all'email {{currentUserEmail}}.
                                    Sarà successivamente necessario un intervento nel caso lo stato delle timbrature lo richieda.
                                </v-container>
                            </v-stepper-content>
                        </v-stepper-items>
                     </v-stepper>
               

                <v-card-actions class="justify-end align-end">
                    <v-btn
                        @click="progress -= 1"
                        v-if="progress > 1"
                        small
                        :disabled="consolidating"
                    >  
                        <v-icon>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <v-btn
                        @click="progress += 1"
                        v-if="progress < 3"
                       :disabled="!navigationCondition[progress - 1]"
                        color="light-blue lighten-4"
                    >  
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                    <v-btn
                        color="light-blue lighten-3"
                        @click="createConsolidationRecord"
                        v-if="progress >= 3"
                        class="px-4"
                        :loading="consolidating"
                        :disabled="consolidating"
                    >
                        <v-icon left>
                            mdi-check
                        </v-icon>
                        Avvia controllo
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>
<script>
import companyApi from '../../../api/company'
import consolidationRecordApi from '../../../api/consolidationRecord'

import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'

import * as dataStorageActions from '../../../store/actions/dataStorage'

export default {
    data: function() {
        return {
            progress: 1,
            picker: null,
            selectedCompanies: [],
            consolidating: false,
        }
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        ...mapActions({
            saveCompanies: dataStorageActions.DATASTORAGE_SETCOMPANIES,
        }),
        cancelMethod: function() {
            // this.$refs.form.reset();
            if(!this.consolidating) {
                this.$emit('dialog-close');
            }
            
        },
        fetchCompanies: function() {
            companyApi.getCompanies().then(response => {
                if(response.success) {
                    this.saveCompanies(response.data);
                }
            })
        },
        createConsolidationRecord: function() {
            this.consolidating = true;
            consolidationRecordApi.createConsolidationRecord(this.selectedCompanies, this.selectedYear, this.selectedMonth).then(response => {
                if(response.success) {
                    this.$emit('refresh-consolidation-records');
                    this.$emit('dialog-close');
                }
                this.consolidating = false;
            })
        }
    },
    watch: {
        show: function(val){
            if (val) {
                this.progress = 1;
                this.picker = null;
                this.selectedCompanies = [];
            }
        }
    },
    computed: {
        ...mapState({
            companies: state => state.dataStorage.companies,
            currentUserEmail: state => state.currentUser.email
        }),
        selectableCompanies: function() {
            return this.companies.map(company => company.companyName);
        },
        navigationCondition: function() {
            return [
                !!this.picker,
                this.selectedCompanies.length > 0,
            ]
        },
        selectedMonth: function() {
            if(this.picker)
                return parseInt(this.picker.split('-')[1]);
            return null;
        },
        selectedYear: function() {
            if(this.picker)
                return parseInt(this.picker.split('-')[0]);
            return null;
        },
        selectedMonthDate: function() {
            if(this.picker) {
                let chunks = this.picker.split('-');
                return DateTime.fromObject({year: chunks[0], month: chunks[1], day: 1});
            } else {
                return null;
            }
            
        },
        isoSelectedMonthDate: function() {
            if(this.selectedMonthDate && this.selectedMonthDate.invalid == null) {
                return this.selectedMonthDate.toISODate();
            }
            return null;
            
        }
    },
    mounted: function() {
        this.fetchCompanies();
    }
}
</script>
